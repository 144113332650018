<template>
  <base-view icon="upload" title="Importar Analítico del Ejercicio de Egresos por Unidad Administrativa">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <check-authorization
      :requiresAuthorizations="['importar analitico egresos unidad administrativa']"
    >
      <b-modal
        id="momentosContablesModal"
        v-model="showModal"
        centered
        no-close-on-backdrop
        hide-footer
      >
        <template #modal-title>Importar archivo XAML</template>
        <template #default="{cancel}">
          <import-analitico-egresos-unidad-administrativa-form @on-result="cancel()">
            <template #btn-cancel-place="{ clearForm }">
              <b-button
                variant="black"
                size="sm"
                @click="
                cancel()
                clearForm()
              "
              >
                Cancelar
              </b-button>
            </template>
          </import-analitico-egresos-unidad-administrativa-form>
        </template>
      </b-modal>
    </check-authorization>

    <check-authorization :requiresAuthorizations="['importar analitico egresos unidad administrativa']">
      <div class="card card-header-actions">
        <div class="card-header">
          Lista de Analíticos importados previamente
          <!-- Search -->
          <button v-show="!showForm" class="btn btn-success btn-sm ml-2" @click="showModal = true">
            Importar
          </button>
        </div>

        <!-- Contenedor -->
        <div class="card-body">
          <import-analitico-egresos-unidad-administrativa-list @hideForm="showForm = true" @input="onChangePeriodoFiscal"></import-analitico-egresos-unidad-administrativa-list>
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import ImportAnaliticoEgresosUnidadAdministrativaList from '@/components/Imports/AnaliticoEgresosUnidadAdministrativa/ImportAnaliticoEgresosUnidadAdministrativaList'
import ImportAnaliticoEgresosUnidadAdministrativaForm from '@/components/Imports/AnaliticoEgresosUnidadAdministrativa/ImportAnaliticoEgresosUnidadAdministrativaForm.vue'

export default {
  name: 'AdministrativeUnits',

  components: {
    ImportAnaliticoEgresosUnidadAdministrativaList,
    ImportAnaliticoEgresosUnidadAdministrativaForm
  },

  data () {
    return {
      showModal: false,
      showForm: false,

      periodo_id: null
    }
  },

  methods: {
    onChangePeriodoFiscal (value) {
      this.periodo_id = value
    },

    cancel () {
      this.showModal = false
    }
  }
}
</script>
