<template>
  <div>
    <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" />

    <b-overlay :show="loading">
      <template #overlay>
        <loading />
      </template>

      <b-table
        :fields="fields"
        :items="items"
        striped
        bordered
        responsive
        hover
        stacked="lg"
        show-empty
      >
        <template #cell(acciones)="row">
          <u-d-controls
            :id="row.item.id"
            editPath="importAnaliticoEgresosUnidadAdministrativaModule/getAdministrativeUnitToEdit"
            deletePath="importAnaliticoEgresosUnidadAdministrativaModule/deleteData"
            :editPermissions="['importar analitico egresos unidad administrativa']"
            :deletePermissions="['importar analitico egresos unidad administrativa']"
            @on-got-item="up"
          >
            <template #edit>
              <b-button
                class="btn-outline-success mr-2 mb-2 mb-sm-0"
                size="sm"
                variant="white"
                @click="exportar(row.item.id)"
              >
                Exportar
              </b-button>
            </template>
          </u-d-controls>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ImportAnaliticoEgresosUnidadAdministrativaList',

  data () {
    return {
      fields: [
        { key: 'fecha_importacion', label: 'Fecha' },
        { key: 'titulo', label: 'Reporte' },
        { key: 'archivo.original_name', label: 'Archivo' },
        { key: 'fuente_financiamiento.clave', label: 'Fuente de Financiamiento' },
        { key: 'aprobado', label: 'Aprobado' },
        { key: 'acciones', label: 'Acciones' }
      ],

      reset: false
    }
  },

  computed: {
    ...mapGetters('importAnaliticoEgresosUnidadAdministrativaModule', { items: 'getData', loading: 'getLoadingData' })
  },

  methods: {
    ...mapActions('importAnaliticoEgresosUnidadAdministrativaModule', ['getData', 'setPeriodoFiscalId']),

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.setPeriodoFiscalId(periodo_fiscal_id)
      const { error, message } = await this.getData(periodo_fiscal_id)

      this.$emit('input', periodo_fiscal_id)
      if (error) return this.$notify({ error, message }, 'Listado de unidades administrativas')
    },

    async exportar (id) {
      const getval = await this.$store.dispatch('importAnaliticoEgresosUnidadAdministrativaModule/export', {
        id: id
      })

      await this.$onDownload(getval, 'estado_analitico_egresos_unidad_administrativa.xlsx')

      this.$notify({ message: 'Analítico exportado' })
    },

    getStatusVariant (status) {
      return status === 1 ? 'success' : 'danger'
    },

    getStatusText (status) {
      return status === 1 ? 'Activo' : 'Inactivo'
    },

    up () {
      window.scrollTo(0, 50)
      this.$emit('hideForm')
    }
  }
}
</script>
